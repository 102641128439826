<template>
  <el-dialog
    id="OrgAliasHistoryDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="80%"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="orgsAliasHistoryTable"
          id="orgsAliasHistoryTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="history"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllModules } from "@ag-grid-enterprise/all-modules";
  import Icon from "@/components/IconPack";

  function parseError(error) {
    if (typeof error == "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join("; ");
    } else return error;
  }

  export default {
    name: "OrgAliasHistory",
    components: { AgGridVue, Icon },
    props: ["busEvent"],
    data() {
      var that = this;
      return {
        loading: false,
        modules: AllModules,
        dialogShow: false,
        dialogHeight: 0,
        resizeObserver: null,
        title: $tt("Пошук"),
        target: null,
        axiomaId: null,
        filterString: "",
        selectedRows: null,
        waitForAnswer: false,
        keyUpTimer: null,
        filterHasChanged: false,
        gridApi: null,
        gridColumnApi: null,
        noRowsToShowTemplate: `<div><img src="${require("../../../assets/icons/no_data.svg")}"></div>`,
        gridOptions: {
          suppressCellSelection: true,
          getRowNodeId: function(data) {
            return data._id;
          },
          immutableData: true,
          rowHeight: 32,
          floatingFiltersHeight: 32,
          statusBar: {
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
            ],
          },
        },
        sideBar: {
          toolPanels: ["columns", "filters"],
          position: "left",
          hiddenByDefault: false,
        },
        defaultColDef: {
          suppressNavigable: true,
          cellClass: "no-border",
          resizable: true,
          sortable: true,
          tooltipValueGetter: function(params) {
            return params.value;
          },
          floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
          suppressMenu: false,
          filter: "agTextColumnFilter",
          floatingFilterComponentParams: { suppressFilterButton: true },
        },

        columns: [
          {
            headerName: $tt("Дата"),
            field: "created.timestamp",
            flex: 1,
            sort: "desc",
            valueGetter: (params) => {
              return (
                new Date(params.data.created.timestamp).toLocaleDateString("ru-RU") + " " + new Date(params.data.created.timestamp).toLocaleTimeString("ru-RU")
              );
            },
          },
          {
            headerName: $tt("Користувач"),
            field: "created.userId",
            flex: 2,
          },
          {
            headerName: $tt("Операція"),
            field: "operation",
            flex: 1,
            valueGetter: (params) => {
              var result = "";
              switch (params.data.parentAxiomaId) {
                case -1:
                  result = $tt("Перенаправлення зкинуто");
                  break;
                case 0:
                  result = $tt("Організацію встановлено як корпорацію");
                  break;
                default:
                  result = $tt("Корпорацію встановлено");
                  break;
              }
              return result;
            },
          },
          {
            headerName: $tt("Корпорація"),
            field: "name",
            flex: 3,
            valueGetter: (params) => {
              var result = "";
              switch (params.data.parentAxiomaId) {
                case -1:
                  result = params.data.corpData.corp_name;
                  break;
                case 0:
                  result = params.data.corpData.name_with_area;
                  break;
                default:
                  result = params.data.corpData.corp_name;
                  break;
              }
              return result;
            },
          },
          {
            headerName: $tt("Попередня корпорація"),
            field: "prevName",
            flex: 3,
            valueGetter: (params) => {
              var result = params.data.prevCorpData.corp_name;
              return result;
            },
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      };
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    },
    created() {
      var that = this;
      bus.$on(this.busEvent + ".show", (data) => {
        if (!that.dialogShow) {
          that.title = (data && data.title) || that.title;
          that.axiomaId = data.axiomaId;
          that.loading = true;
          that.dialogShow = true;
        }
      });
      bus.$on(this.busEvent + ".hide", () => {
        that.dialogShow = false;
        that.resizeObserver = null;
      });
    },
    computed: {
      history() {
        return this.$store.getters["orgs/aliasesHistory"]();
      },
    },
    watch: {
      dialogHeight(value) {
        var containerHeight = value - 48 - 48 - 42;
        // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
        this.$refs.flex_container.style.height = `${containerHeight}px`;
      },
    },
    methods: {
      onGridReady() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        var state = this.$store.getters["main/userSettings"]("editOrgAliasValueGridColumnState");
        if (state) this.gridColumnApi.setColumnState(state);
      },
      observeHeight() {
        var that = this;
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(function() {
            that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
          });
          this.resizeObserver.observe(this.$el.children[0]);
        }
      },
      unobserveHeight() {
        this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
        this.resizeObserver = null;
        this.dialogHeight = 0;
      },
      onOpen() {
        this.$store.dispatch("orgs/clearOrgAliasesHistory");
        this.observeHeight();
      },
      onClose() {
        this.unobserveHeight();
      },
      onOpened() {
        this.$store
          .dispatch("orgs/getOrgAliasesHistory", this.axiomaId)
          .finally(() => {
            this.loading = false;
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка"), err);
          });
      },
      onCommit() {
        this.dialogShow = false;
      },
      onGridSelectionChanged() {
        var rows = this.gridOptions.api.getSelectedRows();
        if (rows && rows.length > 0) {
          this.selectedRows = rows;
        } else {
          this.selectedRows = null;
        }
      },
      onFormEnter() {},
      onGridColumnsChange() {
        var that = this;
        if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
        this.changeColumnsTimer = setTimeout(() => {
          var state = that.gridColumnApi.getColumnState();
          clearTimeout(that.changeColumnsTimer);
          that.$store.dispatch("main/updateUserSettings", { editOrgAliasValueGridColumnState: state });
        }, 500);
      },
      gridLocale(key, defaultValue) {
        var localized = $tt("grid." + key);
        return localized ? localized : defaultValue;
      },
    },
  };
</script>

<style lang="scss" scope>
  // #OrgAliasHistoryDialog .flex_container {
  //   padding: 16px 0px 0px 0px !important;
  // }

  // #OrgAliasHistoryDialog.my-dialog .el-dialog--center .el-dialog__body {
  //    padding: 0px 16px 0 16px !important;
  // }
  #OrgAliasHistoryDialog.el-dialog {
    max-height: 740px !important;
  }
</style>
